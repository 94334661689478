import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { FilterObject } from 'state-domains/domain/drillhole';
import { CoordinatesResponse, GridEntry } from 'state-domains/domain/subscription';
import { buildConfigurationGridsUrlWithFilters } from 'state-domains/network';
import { convertToCamel } from 'state-domains/utils/typeCast';

import { sendRetrieveRequest } from '../../../utils';

export const loadConfigurationGrids = (
    filters: FilterObject[],
): Observable<{ items: GridEntry[] }> => {
    const obs = sendRetrieveRequest(buildConfigurationGridsUrlWithFilters(filters));
    return obs.pipe(
        switchMap(({ response }: AjaxResponse<CoordinatesResponse>) =>
            observableOf({
                items: convertToCamel<GridEntry[]>(response.Grid),
            }),
        ),
    );
};
